<template>
  <div class="mt-5">
    <div
      v-if="$apolloData.queries.doctor.loading"
      ref="isLoading"
      class="text-center text-muted vertical-centered"
    >
      <font-awesome-icon
        :icon="['fas', 'circle-notch']"
        class="fa-spin fa-5x my-4"
      />
      <small class="d-block">
        CARGANDO
      </small>
    </div>
    <div
      v-else-if="doctor && Object.entries(doctor).length && doctor.fname"
      ref="elseIfDoctorLength"
      class="user-profile"
      :style="`background-color: ${bgColor}; color: ${txtColor};` +
        `transition: all .5s ease;`"
    >
      <div class="container">
        <main class="user-personal-info">
          <img
            class="rounded"
            :src="imgSrc"
            :alt="`Perfil de ${personNames.fullName}`"
          >
          <header class="mt-3 mt-md-0 ml-md-3">
            <h1 class="h1-responsive font-weight-bold">
              <span class="d-block d-md-inline mt-1">
                {{ personNames.names }}
              </span>
              <span class="d-block d-md-inline mt-1 ml-1">
                {{ personNames.lnames }}
              </span>
            </h1>
            <p class="h4-responsive font-italic">
              {{ searchTags() }}
            </p>
          </header>
        </main>
        <div
          :class="['text-center',
                   {'animated shake delay-5s': addBtnCitaAnimation}]"
        >
          <mdb-btn
            ref="mdbBtnShoaAgendarCita"
            class="my-3"
            color="success-base"
            icon="calendar-alt"
            @click="showAgendarCita = true"
          >
            {{ doctor.profileInfo.buttonLabel || 'Solicitar cita' }}
          </mdb-btn>
        </div>
        <pre
          class="my-md-3 roboto-text pre-wrap currentColor"
        >{{ doctor.profileInfo.wysiwyg }}</pre>
        <div
          v-if="doctor.contactsInfo.length"
          ref="vIfDoctorContactsInfoLength"
          class="bg-card"
        >
          <p class="h5-responsive font-weight-bold text-center">
            Contacto
          </p>
          <ul class="contact-list">
            <li
              v-for="(contact, iContact) in doctor.contactsInfo"
              :key="iContact"
              :class="[
                'contact',
                doctor.contactsInfo.length > 2 ? 'split-3' : 'split-2',
                contact.icon
              ]"
            >
              <span class="title">{{ contact.name }}</span>
              <a
                :href="contact.url"
                target="_blank"
                class="currentColor"
                rel="noopener noreferrer nofollow"
                :title="contact.title"
              >
                {{ contact.value }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      v-else
      class="py-4 container vertical-centered text-muted"
    >
      <font-awesome-icon
        :icon="['fas', 'user-slash']"
        class="my-2 fa-5x"
      />
      <header class="h2-responsive font-weight-bold">
        ¡Lo sentimos!
      </header>
      <p class="lead text-center">
        <span class="d-sm-block">
          El doctor que buscas no ha configurado
        </span>
        <span class="d-sm-block">
          su perfil público o está mal escrito el enlace.
        </span>
      </p>
      <p class="text-center">
        Búscalo a través de nuestro directorio médico y
        <span class="d-sm-block">
          descubre más especialistas en todas las áreas.
        </span>
      </p>
      <router-link
        v-slot="{ navigate }"
        :to="{ name: 'home' }"
        custom
      >
        <span
          role="link"
          class="btn btn-primary-base px-3"
          @click="navigate"
          @keypress.enter="navigate"
        >
          <font-awesome-icon
            icon="chevron-left"
            class="mr-1"
          />
          Ver directorio médico
        </span>
      </router-link>

      <p class="h3-responsive font-weight-bold my-2">
        ¿Eres el médico?
      </p>
      <p class="text-sm-center">
        Te invitamos a crear tu perfil público a través del sistema,
        <span class="d-sm-block">
          en la sección de Configuración > Perfil público.
        </span>
      </p>
    </div>
    <div
      v-if="showChat"
      class="chat-container"
    >
      <div class="chat-header">
        <p>
          Soy {{ botName }}, pregúntame.
        </p>
        <mdb-btn
          flat
          dark-waves
          icon="times"
          class="p-1 m-0"
          @click="showChat = false"
        />
      </div>
      <div class="chat-body">
        <div>
          <p
            class="answer"
          >
            <span class="message">
              Pregúntame algo dirigido a {{ personNames.fullName }},
              puedo responderte sobre sus horarios, servicios o
              cualquier otra pregunta que me haya enseñado a responder.
            </span>
          </p>
          <div
            class="chat-avatar"
          >
            <img
              v-if="botImage"
              :src="botImage"
              :alt="botName"
              class="w-3rem"
            >
            <img
              v-else
              src="../../../public/img/vectores/fab icon.svg"
              alt="AmiiBot"
              class="w-3rem"
            >
          </div>
        </div>
        <div
          v-for="(message, m) in chatMessages"
          :key="m"
        >
          <p
            :class="message.type == 'question' ? 'question' : 'answer'"
          >
            <span class="message">
              {{ message.text }}
            </span>
          </p>
          <div
            v-if="message.type == 'answer'"
            class="chat-avatar"
          >
            <img
              v-if="botImage"
              :src="botImage"
              :alt="botName"
              class="w-3rem"
            >
            <img
              v-else
              src="../../../public/img/vectores/fab icon.svg"
              alt="AmiiBot"
              class="w-3rem"
            >
          </div>
        </div>
      </div>
      <div class="chat-input">
        <mdb-input
          v-model="chatMessage"
          type="text"
          class="col p-0 my-0"
          outline
          :disabled="btnDisabled"
          @keyup.enter="sendQuestion()"
        />
        <mdb-btn
          flat
          dark-waves
          :icon="btnDisabled ? 'circle-notch' : 'paper-plane'"
          class="p-1 mt-0 btn-outline-primary-base"
          :icon-class="btnDisabled ? 'fa-spin' : ''"
          :disabled="btnDisabled"
          @click="sendQuestion()"
        />
      </div>
    </div>
    <template v-else>
      <mdb-btn
        dark-waves
        class="fab-btn"
        @click="showChat = true"
      >
        <img
          v-if="botImage"
          :src="botImage"
          :alt="botName"
          class="w-3rem"
        >
        <img
          v-else
          src="../../../public/img/vectores/fab icon.svg"
          alt="AmiiBot"
          class="w-3rem"
        >
      </mdb-btn>
      <p class="fab-text">
        <span>
          ¿Tienes alguna duda?
        </span>
      </p>
    </template>
    <ModalAgendarCita
      :show-modal="showAgendarCita"
      :doctor="doctor"
      @close="showAgendarCita = $event"
    />
  </div>
</template>
<script>
import {mdbBtn, mdbInput} from 'mdbvue';
import ModalAgendarCita from '@/components/modalAgendarCita.vue';
import {capitalizeAll} from '@/utils/validations.js';
import gql from 'graphql-tag';
import doctorProfileGql from '@/graphql/doctorProfile.gql';

const imgPath = process.env.NODE_ENV === 'production'
              ? process.env.VUE_APP_MULTIMEDIA_HTTPS
              : process.env.VUE_APP_MULTIMEDIA_HTTP;

const amiibotUrl = process.env.NODE_ENV === 'production'
              ? process.env.VUE_APP_AMIIBOT_HTTPS
              : process.env.VUE_APP_AMIIBOT_HTTP;
export default {
  name: 'PerfilDeUsuario',
  components: {
    mdbBtn,
    mdbInput,
    ModalAgendarCita,
  },
  data() {
    return {
      doctor: {
        profileInfo: {
          nameBot: 'AmiiBot',
        },
        contactsInfo: [],
        works_at: [],
      },
      showChat: false,
      btnDisabled: false,
      chatMessage: '',
      chatMessages: [],
      showAgendarCita: false, // Abre modal agendar cita
      addBtnCitaAnimation: false,
      imgPath: imgPath+'dir/',
      wuid: '',
    };
  },
  computed: {
    bgColor() {
      return this.doctor.profileInfo.bgColor
        ? this.doctor.profileInfo.bgColor
        : 'rgb(255, 255, 255)';
    },
    imgSrc() {
      return this.doctor.profileInfo.image
        ? this.imgPath+this.doctor.profileInfo.image.url
        : `https://www.gravatar.com/avatar/${this.doctor.profileInfo.avatar}?s=150`;
    },
    txtColor() {
      return this.doctor.profileInfo.color
        ? this.doctor.profileInfo.color
        : 'rgb(33, 37, 41)';
    },
    contactListLength() {
      const longitud = this.doctor.contactsInfo.length;
      return longitud > 2 ? 'columns3' : longitud == 2 ? 'columns2' : '';
    },
    personNames() {
      return {
        names: `${this.doctor.prename ? this.doctor.prename + ' ' : ''}`
          + `${this.doctor.fname}`
          + `${this.doctor.mname ? ' ' + this.doctor.mname : ''}`,
        lnames: `${this.doctor.lname}`
          + `${this.doctor.llname ? ' ' + this.doctor.llname : ''}`
          + `${this.doctor.posname ? ' ' + this.doctor.posname : ''}`,
        fullName: `${this.doctor.prename ? this.doctor.prename+' ' : ''}`
          + `${this.doctor.fname}`
          + `${this.doctor.mname ? ' ' + this.doctor.mname : ''}`
          + ` ${this.doctor.lname}`
          + `${this.doctor.llname ? ' ' + this.doctor.llname : ''}`
          + `${this.doctor.posname ? ' ' + this.doctor.posname : ''}`,
      };
    },
    botName() {
      let name = 'AmiiBot';
      if (
        this.doctor.profileInfo
        && this.doctor.profileInfo.nameBot
      ) {
        name = this.doctor.profileInfo.nameBot;
      }
      return name;
    },
    botImage() {
      let botImg = null;
      if (
        this.doctor.profileInfo
        && this.doctor.profileInfo.avatarBot
      ) {
        botImg = this.imgPath+this.doctor.profileInfo.avatarBot;
      }
      return botImg;
    },
  },
  mounted() {
    this.addBtnCitaAnimation = true;
    this.animationTimeout();
    if (typeof localStorage !== 'undefined') {
      if (!localStorage.getItem('wuid')) {
        try {
          const wuid = self.crypto.randomUUID();
          this.wuid = wuid;
          localStorage.setItem('wuid', wuid);
        } catch (error) {
          console.log('Navegador no actualizado para UUID');
        }
      } else {
        this.wuid = localStorage.getItem('wuid');
      }
    }
  },
  methods: {
    searchTags() {
      let tags = '';
      if (this.doctor.profileInfo
        && this.doctor.profileInfo.searchTags
        && this.doctor.profileInfo.searchTags.length
        && this.doctor.profileInfo.searchTags[0].name) {
        this.doctor.profileInfo.searchTags.map((tag) => {
          if (tags.length > 0) tags += ', ';
          tags += capitalizeAll(tag.name);
        });
      }
      return tags;
    },
    async sendQuestion() {
      if (this.chatMessage.trim()) {
        const chat = document.querySelector('.chat-body');
        this.chatMessages.push({
          type: 'question',
          text: this.chatMessage,
        });
        this.$nextTick(() => {
          chat.scrollTo(0, chat.scrollHeight);
        });

        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        try {
          this.btnDisabled = true;
          await fetch(
              amiibotUrl+this.$route.params.usuario.toLowerCase()
              , {
                method: 'post',
                headers: myHeaders,
                body: JSON.stringify({
                  pregunta: this.chatMessage,
                  uid: this.wuid,
                }),
              },
          )
              .then((res)=>res.json())
              .then((json)=> {
                this.chatMessage = '';

                this.chatMessages.push({
                  type: 'answer',
                  text: json.respuesta,
                });
              })
              .catch(()=>{
                this.chatMessages.push({
                  type: 'answer',
                  text: 'Intenta enviar de nuevo, hubo un error.'
                  +' También puedes comunicarte por los medios de'
                  +' contacto visibles en el perfil.',
                });
              }).finally(()=>{
                this.btnDisabled = false;
                this.$nextTick(() => {
                  chat.scrollTo(0, chat.scrollHeight);
                });
              });
        } catch (error) {
          // console.log(error);
          this.btnDisabled = false;
        }
      }
    },
    animationTimeout() {
      setTimeout(() => {
        if (document.querySelector('.fab-text')) {
          document.querySelector('.fab-text').classList.toggle('animation');
        }
        this.animationTimeout();
      }, 4000);
    },
  },
  apollo: {
    doctor() {
      return {
        query: gql`${doctorProfileGql}`,
        variables: {
          usuario: this.$route.params.usuario.toLowerCase(),
        },
        update: (data) => data.Doctor[0],
      };
    },
  },
};
</script>
<style lang="scss">
  // family className content weight rotation
  $links-type: (
    "Font Awesome 5 Brands" "facebook" "\f09a" 0 false,
    "Font Awesome 5 Brands" "instagram" "\f16d" 0 false,
    "Font Awesome 5 Brands" "linkedIn" "\f08c" 0 false,
    "Font Awesome 5 Brands" "telegram" "\f2c6" 0 false,
    "Font Awesome 5 Brands" "tiktok" "\e07b" 0 false,
    "Font Awesome 5 Brands" "twitter" "\f099" 0 false,
    "Font Awesome 5 Brands" "whatsApp" "\f232" 0 false,
    "Font Awesome 5 Brands" "youtube" "\f167" 0 false,
    "Font Awesome 5 Free" "book" "\f02d" 900 false,
    "Font Awesome 5 Free" "email" "\f0e0" 400 false,
    "Font Awesome 5 Free" "file" "\f15b" 900 false,
    "Font Awesome 5 Free" "filePdf" "\f1c1" 900 false,
    "Font Awesome 5 Free" "link" "\f0c1" 900 false,
    "Font Awesome 5 Free" "phone" "\f879" 900 rotate(80deg),
  );

  /*
    .user-profile
    Establece altura mínima de la section para poder aplicar
    el color de fondo en toda la pantalla
  */
  .user-profile {
    min-height: calc(100vh - 48px);
    padding: 1.5rem 0 1rem;
  }

  .user-personal-info {
    text-align: center;
    img {
      max-width: 150px;
    }

    @media screen and (min-width: 768px) {
      align-items: center;
      display: flex;
      justify-content: center;
      text-align: left;
    }
  }

  .bg-card {
    background-color: rgba(191, 187, 187, 0.2);
    border-radius: 5px;
    margin: 1.5rem 0;
    padding: 1rem;
  }

  .contact-list {
    list-style-type: none;

    @media screen and (min-width: 768px) {
      display: flex;
      justify-content: space-between;
      flex-flow: row wrap;
    }

    .contact {
      margin: .5rem 0 .5rem 26px;
      position: relative;

      &::before {
        position: absolute;
        left: -20px;
      }

      @each $family, $class, $content, $weight, $transform in $links-type {
        &.#{$class}::before {
          content: $content;
          font-family: $family;

          @if $weight != 0 {
            font-weight: $weight;
          }

          @if $transform != false {
            transform: $transform;
          }
        }
      }

      &.split-2 {
        width: 40%;
      }
      &.split-3 {
        width: 30%;
      }

      .title {
        display: block;
        font-weight: 700;
      }
    }
  }
  .currentColor {
    color: currentColor;
  }
  .fab-btn {
    background-color: $gris-claro-home !important;
    border: 1px solid rgba(128, 128, 128, 0.2);
    border-radius: 3rem;
    bottom: .5rem;
    margin: 0;
    right: .5rem;
    padding: .5rem;
    position: fixed;
  }
  .fab-text {
    display: flex;
    bottom: 3rem;
    flex-direction: column;
    position: fixed;
    right: 3rem;
    &.animation {
      animation: rotate-scale-up 0.65s linear;
    }
    span {
      background-color: $gris-claro-home !important;
      border: 1px solid rgba(128, 128, 128, 0.2);
      border-radius: .5rem;
      padding: .5rem;
    }
    &::after {
      align-self: flex-end;
      content: "\f0d7";
      color: rgba(128, 128, 128, 0.2);
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      margin-right: .5rem;
      margin-top: -10px;
    }
  }
  .chat-container {
    background-color: $gris-claro-home !important;
    border: 1px solid rgba(128, 128, 128, 0.2);
    border-radius: 1rem 1rem 0 0;
    bottom: 0;
    right: 0;
    margin: 0 .5rem;
    position: fixed;
    overflow: hidden;
    @media (min-width: 576px) {
      right: .5rem;
      width: 285px;
    }

    .chat-header {
      padding: .5rem;
      background-color: $primary-color;
      display: flex;
      justify-content: space-between;

      p, button:hover {
        color: #ffff;
        font-weight: bold;
        margin: 0;
      }
      button {
        color: rgba(10, 10, 10, 0.488);
        border: 1px solid rgba(56, 56, 56, 0.2);
      }
    }
    .chat-avatar {
      background-color: #ffff;
      border-radius: 2rem;
      padding: .3rem;
      width: fit-content;
      img {
        height: 1.7rem;
        width: 1.7rem;
      }
    }
    .chat-body {
      max-height: 20rem;
      overflow-y: scroll;
      div {
        padding: 0 .5rem 0 .5rem;
        display: flex;
        flex-direction: column;
        p {
          display: flex;
          flex-direction: column;
          width: fit-content;
          margin: 0;

          .message {
            font-size: .9rem;
            padding: .5rem;
            margin: 0;
            margin-top: .5rem;
            border: 1px solid rgba(128, 128, 128, 0.2);
            border-radius: .5rem;
          }
          &.question {
            align-self: flex-end;
            &::after {
              align-self: flex-end;
              content: "\f0d7";
              color: rgba(128, 128, 128, 0.2);
              font-family: "Font Awesome 5 Free";
              font-weight: 900;
              margin-right: .5rem;
              margin-top: -10px;
            }
          }
          &.answer {
            &::after {
              content: "\f0d7";
              color: rgba(128, 128, 128, 0.2);
              font-family: "Font Awesome 5 Free";
              font-weight: 900;
              margin-left: 1rem;
              margin-top: -9px;
            }
        }
        }
      }
    }

    .chat-input {
      padding: .5rem;
      display: flex;
    }
  }
  .w-3rem {
    width: 3rem;
  }
  @keyframes rotate-scale-up {
  0% {
    transform: scale(1) rotateZ(0);
  }
  25% {
    transform: scale(1.1) rotateZ(40deg);
  }
  50% {
    transform: scale(1.1) rotateZ(0deg);
  }
  75% {
    transform: scale(1.1) rotateZ(40deg);
  }
  100% {
    transform: scale(1) rotateZ(0);
  }
}
</style>
